<template>
    <div>
        <Navbar />
        <Header :category="'organizers'" :bannerTitle="bannerTitle" :breadCrumb="borrowSpaceCrumb" />
        <AlbumBody :bannerTitle="bannerTitle" />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import AlbumBody from './AlbumBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'


    export default {
        name: 'AlbumMain',
        metaInfo() {
            return {
                title: this.$t("navbar.menu_2_5"),
            }
        },
        components: {
            Navbar,
            Header,
            AlbumBody,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                bannerTitle: this.$t('navbar.menu_2_5'),
                borrowSpaceCrumb: [{
                    name: this.$t('navbar.menu_2')
                }]
            }
        }
    }
</script>

<style scoped>
    .cover-image {
        margin-bottom: 50px;
        width: 100%;
        height: 400px;
    }

    .in-btn {
        margin-left: 30px;
        margin-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }
</style>